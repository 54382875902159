<template>
  <div id="main-content" class="main-content-normal" :class="{'main-content-bigger':$store.state.mainBig}">
    <div class="container-fluid">

      <div class="block-header">
        <el-row :gutter="10">
          <el-col :span="12">
            <!-- <h1 v-html="$t('alarm.alarmchaxuan')"></h1> -->
            <el-button v-html="$t('alarm.alarmchaxuan')" :type="typeAlarm==0?'primary':'default'" @click="chageTypeAlarm(0)" round></el-button>
            <el-button v-html="$t('alarm.alarmsetting')" :type="typeAlarm==1?'primary':'default'" @click="chageTypeAlarm(1)" round></el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 添加报警邮箱弹框 -->
      <div id="EmailAddInfo" class="modal fade bd-Register-modal-lg" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title h4" id="myLargeModalLabel">
                <span class="fa-layers fa-fw fa-2x">
                  <i class="fad fa-envelope"></i>
                  <i class="fad fa-plus-circle" data-fa-transform="shrink-8 right-12.2 down-1"></i>
                </span>
                <span>
                  <!-- 添加报警邮箱 -->
                  {{ $t('alarm.EmailAdd') }}
                </span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" @click="EmailAddInfoHide" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="modal-body">
                <el-row :gutter="0">
                  <el-col :span="24">
                    <div class="form-group c_form_group">
                      <label>
                        <!-- 请输入Email地址 -->
                        {{ $t("alarm.PleaseInputEmail") }}
                        <span class="float-right text-tomato" v-show="errors.has('addEmailParam.userEmail')"><i class="fad fa-exclamation-circle"></i>
                          <span class="text-tomato-55 small">{{
                          errors.first("addEmailParam.userEmail")
                        }}</span></span>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fad fa-envelope text-dark-15"></i>
                          </span>
                        </div>
                        <input class="form-control" data-vv-scope="addEmailParam" v-validate="'required|email'" name="userEmail" type="text" v-model="addEmailParam.email" :placeholder="$t('authority.ExampleEmail')" />
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <template class="email-setting">
                      <span class="float-right text-tomato" v-show="checkedFailureTypes.length == 0"><i class="fad fa-exclamation-circle"></i>
                        <span class="text-tomato-55 small">
                          {{ $t("alarm.msg.chooseCode") }}
                        </span></span>
                      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $t('alarm.ChooseAll') }}</el-checkbox>
                      <el-checkbox-group v-model="checkedFailureTypes">
                        <el-checkbox v-for="types in failureTypes" :label="types.failureTypeId" :key="types.failureTypeId">{{ getFailureTypeName(types) }}</el-checkbox>
                      </el-checkbox-group>
                    </template>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" @click="EmailAddInfoHide">
                <!-- 关闭 -->
                {{ $t("authority.StSonList.DialogBtn.Close") }}
              </button>
              <el-button type="button" class="btn btn-primary theme-bg gradient js-sweetalert" @click="addUserAlertEmailSend" :disabled="errors.has('addEmailParam.userEmail') || checkedFailureTypes.length==0">
                <!-- 确认添加 -->
                {{ $t("authority.StSonList.DialogBtn.ConfirmAdd") }}
              </el-button>
            </div>

          </div>
        </div>
      </div>

      <!-- 报警邮件设置 -->
      <div class="row clearfix mt-4" v-show="typeAlarm==1">
        <div class="col-xl-12">
          <el-card shadow="hover">
            <!-- 提示语 仅在日语状态下显示 -->

            <div class="email-alert" v-if="$store.state.languageId==='ja'">
              <el-alert type="success" :closable="false"><i class="fad fa-exclamation-circle fa-w-16"></i> {{ $t('alarm.EmailAlert') }}</el-alert>
            </div>
            <el-collapse v-model="activeNames" accordion @change="selCollapse" class="email-list">
              <el-collapse-item :name="index+''" v-for="(userEmail,index) in updateList" :key="index">
                <template slot="title">
                  <div class="email-title" @click="stopProp">
                    <div class="email-title-left" v-if="activeNames.indexOf(index+'')==-1">
                      <span class="email-style">{{userEmail.email}}</span>
                      <el-button size="mini" v-if="userEmail.isSend==0" @click="sendTestMail(userEmail,index)" round><i class="fa fa-paper-plane"></i> {{ $t('alarm.SendingTestMail') }}</el-button>
                      <el-button size="mini" v-else round loading>{{ $t('alarm.CountDown') }}<span class="CountDownNumber" v-html="userEmail.isSend"></span></el-button>
                      <span @click="showTips(userEmail.emailTipsId)" class="email-plus-alert ml-2" v-if="userEmail.emailTipsId!==1 && userEmail.emailTipsId!==null"><i class="fad fa-exclamation-circle fa-w-16"></i><span v-html="getTips(userEmail)"></span></span>
                    </div>
                    <!-- 编辑状态时启用 -->
                    <div class="email-title-left" v-else>
                      <span class="email-input">
                        <el-input :placeholder="$t('alarm.PleaseInputEmail')" data-vv-scope="userEmail" v-validate="'required|email'" name="userEmail" v-model="userEmail.email" size="small" clearable></el-input>
                        <span class="text-tomato" v-show="errors.has('userEmail.userEmail')"><i class="fad fa-exclamation-circle"></i>
                          <span class="text-tomato-55 small">{{
                          errors.first("userEmail.userEmail")
                        }}</span></span>
                      </span>
                    </div>
                    <div class="email-title-right">
                      <el-button size="mini" type="danger" icon="el-icon-delete" circle plain @click="handleDelete(index,userEmail.userAlertId)"></el-button>
                    </div>
                  </div>
                </template>
                <template class="email-setting">
                  <div class="setting-frame">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checked=>handleCheckAllChangeUpdate(checked,userEmail)">{{ $t('alarm.ChooseAll') }}</el-checkbox>
                    <el-checkbox-group v-model="updateCheckedFailureTypes">
                      <el-checkbox v-for="types in failureTypes" :label="types.failureTypeId" :key="types.failureTypeId">{{ getFailureTypeName(types) }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="button-frame">
                    <el-button type="primary" size="mini" round @click="updateUserAlertEmailSend(userEmail)" :disabled="errors.has('userEmail.userEmail')"> {{ $t('alarm.msg.saveSetting') }}</el-button>
                  </div>
                </template>
              </el-collapse-item>
            </el-collapse>

            <!-- 满3个邮箱启用 -->
            <div class="email-plus" v-if="userEmailList.length>=3">
              <span class="email-plus-alert">{{ $t('alarm.MaxAlert') }}</span>
              <el-button type="danger" icon="el-icon-plus" circle plain disabled></el-button>
            </div>
            <!-- 未满3个邮箱启用 -->
            <div class="email-plus" v-else>
              <el-button type="primary" icon="el-icon-plus" circle plain @click="handleAdd"></el-button>
            </div>

          </el-card>
        </div>
      </div>

      <!-- 报警查询 -->
      <div class="row clearfix mt-4" v-show="typeAlarm==0">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="row clearfix">
                <div class="col-lg-6 col-lg-auto">
                  <select-ps-id-view :cls="'col-12'" allType=true storeStatus=false :psList="psList" @psIdChange="handlePsIdChange" />
                </div>
                <div class="col-lg-6 col-lg-auto" v-show="false">
                  <GroupSirenType :cls="'col-12 text-right'" />
                </div>
              </div>
            </div>

            <!-- 月报表 -->
            <div class="card-body month" v-show="typeCalendar">
              <div class="choose-header">
                <el-row :gutter="10">
                  <el-col :span="12">
                    {{current.currentYear}}<span v-html="$t('alarm.alarmyear')"></span><span class="xx-large">{{current.currentMonth}}</span><span v-html="$t('alarm.alarmmonth')"></span>
                  </el-col>
                  <!-- <span @click="pickPre(current.currentYear,current.currentMonth)">❮</span>
                                <span @click="pickNext(current.currentYear,current.currentMonth)">❯</span> -->
                  <el-row>
                    <el-col :span="12" class="text-right">
                      <el-date-picker @focus="focus()" ref="picker" :clearable="false" v-model="picker.selectMonth" value-format="yyyy-MM" style="width: 40%" @change="changeMonthDatePicker" type="month" :placeholder="$t('alarm.alarmxzy')" :picker-options="picker.pickerOptions" />
                      <el-tooltip class="item ml-4" :content="$t('alarm.alarmckyb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button type="primary" v-html="$t('alarm.alarmmonth')" circle></el-button>
                      </el-tooltip>
                      <el-tooltip class="item" :content="$t('alarm.alarmcknb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button @click="chageTypeCalendar" v-html="$t('alarm.alarmyear')" circle></el-button>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
              <month-Calendar :psId="psId" :searchDate="searchDateMonPicker" :warningsAllType="warningsAllType" :typeCalendar="typeCalendar" @backMonthCalendarClick="handleMonthCalendarClick" :init="randomNum"> </month-Calendar>
            </div>
            <!-- /月报表 -->

            <!-- 年报表 -->
            <div class="card-body year" style="padding:1.25rem 0.75rem" v-show="!typeCalendar">
              <div class="choose-header">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <span class="xx-large">{{picker.selectYear}}</span><span v-html="$t('alarm.alarmyear')"></span>
                  </el-col>
                  <el-row>
                    <el-col :span="12" class="text-right">
                      <el-date-picker @focus="focusYear()" ref="pickerYear" v-model="picker.chooseYear" value-format="yyyy" style="width:40%" @change="changeYearDatePicker" type="year" :placeholder="$t('alarm.alarmxzn')" :picker-options="picker.pickerOptions" />
                      <el-tooltip class="item ml-4" :content="$t('alarm.alarmcknb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button v-html="$t('alarm.alarmyear')" type="primary" circle></el-button>
                      </el-tooltip>
                      <el-tooltip class="item " :content="$t('alarm.alarmckyb')" placement="bottom" popper-class="dark_aooltip">
                        <el-button v-html="$t('alarm.alarmmonth')" @click="chageTypeCalendar" circle></el-button>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </el-row>
              </div>
              <year-Calender :psId="psId" :searchDate="searchDateYearPicker" :warningsAllType="warningsAllType" :typeCalendar="typeCalendar" @backYearCalendarClick="handleYearCalendarClick" :init="randomNum"> </year-Calender>
            </div>
            <!-- /年报表 -->
            <!-- 明细数据 -->
            <warning-Err-Window :monthWinopen="monthWinopen" :yearWinopen="yearWinopen" :psId="psId" :selectPsName="psName" :warningsAllType="warningsAllType" :typeCalendar="typeCalendar" :errDate="errDate" :errType="errType" :clkType="clkType" @backFailureTypeIdClick="handleBackFailureTypeIdClick"> </warning-Err-Window>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import ButtongroupView from '../components/ButtongroupView'
import SelectPsIdView from '../components/HistoryView/SelectPsId.vue'
import GroupSirenType from '../components/AlarmView/GroupSirenType.vue'
import MonthCalendar from '../components/AlarmView/MonthCalendar.vue'
import YearCalender from '../components/AlarmView/YearCalender.vue'
import WarningErrWindow from '../components/AlarmView/WarningErrWindow.vue'

//邮箱设置选项;
const alarmOptions = ['故障', '离线', '日停机报警', '月度低效报警', '月度报表'];
//5种报警类型的语言包如下
//故障：alarm.Fault
//离线：alarm.Offline
//日停机报警：alarm.DailyShutdownAlarm
//月度低效报警：alarm.MonthlyLowEfficiencyAlarm
//月度报表：alarm.MonthlyReport

export default {
  components: { SelectPsIdView, GroupSirenType, MonthCalendar, YearCalender, WarningErrWindow },
  name: 'alarm',
  created () {
    //this.getPsInfos();
    this.typeCalendar = true;
    this.picker.selectMonth = this.formatDate(this.searchDateMonPicker.getFullYear(), (this.searchDateMonPicker.getMonth() + 1), 1)
    this.current.currentYear = this.searchDateMonPicker.getFullYear();
    this.picker.chooseYear = '2022';
    this.current.currentMonth = (this.searchDateMonPicker.getMonth() + 1);
    this.$store.state.isload = false;
    this.getFailureTypes();
    this.getUserAlertEmailSendList();
  },
  mounted () {

  },
  data () {
    return {
      randomNum: null,
      userAlertSubmit: true,
      activeNames: "",
      input: '',

      //邮箱设置 报警类型选择
      checkAll: false,
      checkedAlarm: [],
      alarms: alarmOptions,
      isIndeterminate: true,

      //默认月,反之年
      typeCalendar: true,
      warningsAllType: true,
      typeAlarm: 0,
      clkType: "month",
      // warningsClass:{"failure":"red","offline_pmu":"gray","offline_device":"brown","stop_pmu":"yellow","stop_device":"yellow"},
      //warningsName: { "failureNum": "故障", "offlineNum": "采集器离线", "offline_device": "设备离线", "stop_pmu": "电站日停机", "stop_device": "设备日停机" },
      //选中的电站id
      psId: "",
      psName: "",
      psList: null,
      minDate: null,
      //时间
      searchDateMonPicker: new Date(),
      searchDateYearPicker: new Date(),
      warningMonthList: [],
      deviceTypeList: [],
      otimer: null,
      isload: false,
      st: { opacity: 1 },
      picker: {
        chooseYear: this.formatDate(parseInt(new Date().getFullYear()), 1, 1),
        selectYear: (new Date().getFullYear() + ""),
        selectMonth: null,
        pickerOptions: {
          disabledDate: this.disabledDate
        }
      },
      current: {
        today: "new Date()",//当前日期
        currentDay: 1,
        currentMonth: 1,
        currentYear: 1970,
        currentWeek: 1,
      },
      errDate: null,
      errType: null,
      monthWinopen: "",
      yearWinopen: "",
      failureTypes: [],//故障类型列表
      failureTypesOptions: [],
      checkedFailureTypes: [],
      userEmailList: [],//用户报警邮箱列表
      addEmailParam: {
        "customize": "",
        "email": "",
        "emailTipsId": "",
        "failureTypes": "",
        "userId": ""
      },
      updateEmailParam: {
        "customize": "",
        "email": "",
        "emailTipsId": "",
        "failureTypes": "",
        "userId": ""
      },
      updateList: [],//修改的列表
      updateCheckedFailureTypes: [],

    }

  },
  methods: {
    focus: function () {
      var self = this;

      //Added this
      this.$nextTick(_ => {
        this.$refs.picker.picker.date = new Date()
      })
    },
    focusYear: function () {
      var self = this;

      //Added this
      this.$nextTick(_ => {
        this.$refs.pickerYear.picker.date = new Date()
      })
    },
    /* 触发报警邮箱添加操作弹框 */
    handleAdd () {
      this.addEmailParam = {
        "customize": "",
        "email": "",
        "emailTipsId": "",
        "failureTypes": "",
        "userId": ""
      }
      this.checkedFailureTypes = []
      $("#EmailAddInfo").modal("show");
    },
    EmailAddInfoHide () {
      $("#EmailAddInfo").modal("hide");
      $(".modal-backdrop").remove();
    },

    //阻止折叠事件传播
    stopProp: function (e) {
      e.stopPropagation()
    },

    //邮箱设置 报警类型选择
    handleCheckAllChange (val) {
      this.checkedFailureTypes = val ? this.failureTypesOptions : [];
      this.isIndeterminate = false;
    },


    handleCheckAllChangeUpdate (val, userEmail) {
      userEmail.tempCheck = val ? this.failureTypesOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedAlarmChange (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.alarm.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.alarm.length;
    },

    handleCheckedAlarmChangeUpdate (value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.alarm.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.alarm.length;
    },
    handleBackFailureTypeIdClick (failureTypeId) {
      this.errType = failureTypeId
    },
    //切换日历和邮件设置控件
    chageTypeAlarm (typeAlarm) {
      //alert(this.typeCalendar)

      this.typeAlarm = typeAlarm;
      this.init();
    },

    //切换月和年的日历控件
    chageTypeCalendar () {
      //alert(this.typeCalendar)
      if (this.typeCalendar) {
        this.typeCalendar = false;//年
      } else {
        this.typeCalendar = true;//月
      }
      //this.init();
    },
    //电站切换
    handlePsIdChange (psBuilddate, psId, psName) {
      var _this = this;
      _this.psId = psId;
      _this.psName = psName;
      // _this.current.currentYear = new Date().getFullYear();
      // _this.current.currentMonth = new Date().getMonth() + 1;
      if (psBuilddate != null) {
        window.sessionStorage.setItem("buildDate", psBuilddate)
      } else {
        window.sessionStorage.setItem("buildDate", null)
      }
      this.$emit("parentPsId");
      this.init();
    },
    handleMonthCalendarClick (day, errType, selectType) {
      console.info("day", day)
      console.info("errType", errType)
      this.errDate = day;
      this.errType = errType;
      this.monthWinopen = "monthWinopen" + Math.floor(Math.random() * 999999);
      this.clkType = selectType;
      //this.$emit("handleMasterClick", day, errType);
    },
    handleYearCalendarClick (day, errType, selectType) {
      console.info("day", day)
      console.info("errType", errType)
      this.errDate = day;
      this.errType = errType;
      this.yearWinopen = "yearWinopen" + Math.floor(Math.random() * 999999);
      this.clkType = selectType;
      //this.$emit("handleMasterClick", day, errType);
    },
    //日历控件选择范围
    disabledDate: (time) => {
      let buildDate = window.sessionStorage.getItem("buildDate");

      if (buildDate != "null") {
        let dts = buildDate.split("-");
        let dt = new Date(parseInt(dts[0]), parseInt(dts[1]) - 1, parseInt(dts[2]));
        return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
      } else {
        let dt = new Date();
        return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
      }
    },
    changeMonthDatePicker (val) {
      var _this = this;
      //console.info(val);
      if (val != undefined && val.indexOf("-") >= 0) {
        var arrPicker = val.split("-");
        _this.current.currentYear = arrPicker[0];
        _this.current.currentMonth = arrPicker[1];
        _this.picker.selectMonth = _this.formatDate(parseInt(arrPicker[0]), parseInt(arrPicker[1]), 1);
        _this.searchDateMonPicker = new Date(_this.picker.selectMonth);
        //_this.picker.pickerOptions.disabledDate = _this.disabledDate;
      }
    },
    changeYearDatePicker (val) {
      var _this = this;
      //console.info(val);
      if (val != undefined) {
        // _this.current.currentYear = val;
        _this.picker.selectYear = parseInt(val);
        _this.searchDateYearPicker = new Date(_this.formatDate(val, 1, 1));
        //_this.picker.pickerOptions.disabledDate = _this.disabledDate;
      }
    },
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    handleSubWaningsKey: function (warningsAllType) {
      this.warningsAllType = warningsAllType;
      //alert(warningsAllType)
    },
    init () {

      this.current.currentYear = new Date().getFullYear()
      this.current.currentMonth = parseInt(new Date().getMonth() + 1)
      this.picker.selectMonth = this.formatDate(parseInt(new Date().getFullYear()), parseInt(new Date().getMonth() + 1), 1);
      this.picker.selectYear = parseInt(new Date().getFullYear());
      this.randomNum = Math.floor(Math.random() * 999999);
      this.picker.chooseYear = this.formatDate(parseInt(new Date().getFullYear()), 1, 1);
    },
    /* 确认删除按钮 */
    handleDelete (index, userAlertId) {
      let _this = this;
      swal({
        //提示的标题 
        title: this.$t('authority.StSonList.SwalDelete.Title'),
        //提示的文本
        text: this.$t('authority.StSonList.SwalDelete.Text'),
        //类型：警告，还有success,error和info
        type: 'warning',
        //显示取消按钮
        showCancelButton: true,
        //确认按钮颜色
        confirmButtonColor: '#ff6347',
        //确认按钮内文本
        confirmButtonText: this.$t('authority.StSonList.SwalDelete.DialogBtn.ConfirmDelete'),
        //取消按钮内文本
        cancelButtonText: this.$t('authority.StSonList.SwalDelete.DialogBtn.ConfirmCancel'),
        closeOnConfirm: false,
        closeOnCancel: false,
      }, async function (isConfirm) {
        if (isConfirm) {
          //if (index % 2 == 0) {
          _this.deleteUserAlertEmail(userAlertId)


          swal({
            //提示的标题
            title: _this.$t('authority.StSonList.SwalDelete.SonTitleSuccess'),
            //提示的文本
            text: _this.$t('authority.StSonList.SwalDelete.SonTextSuccess'),
            //类型：警告，还有success,error和info
            type: "success",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
          });
          //}
          //else {
          //  swal(_this.$t('authority.StSonList.SwalDelete.SonTitleErro'), _this.$t('authority.StSonList.SwalDelete.SonTextErro'), 'error');
          //}

          // //点击确认按钮之后进行的操作，这里success不能改
          // //发送激活邮件
          //   const {data:res}=  await loginVue.$http.get('/service-user/user/sendActiveMail?userCode='+loginVue.loginForm.userCode);
          //   if(res.status==200){
          //       //'邮件发送成功','请到注册邮箱中查收'
          //     swal( loginVue.$t('login.msg.sendActiveEmail'),  loginVue.$t('login.msg.pleaseCheck'), 'success');
          //   }
          //   else{
          //     swal(loginVue.$t('login.code.'+res.status), loginVue.$t('login.msg.goBack'), "warning");
          //   }

        } else {
          //点击取消按钮之后进行的操作，这里error不能改
          //'取消'


          swal({
            //提示的标题
            title: _this.$t('authority.StSonList.SwalDelete.SonTitleCancel'),
            //提示的文本
            text: _this.$t('authority.StSonList.SwalDelete.SonTextCancel'),
            //类型：警告，还有success,error和info
            type: "info",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
          });

        }
      })
    },
    /* 添加报警邮件 /UserAlterEmailSend/addUserAlertEmailSend*/
    addUserAlertEmailSend () {

      var _this = this;
      _this.userAlertSubmit = false;
      if (_this.checkedFailureTypes.length == 0) {
        _this.userAlertSubmit = true;
      }
      const userId = window.sessionStorage.getItem("userId");
      _this.addEmailParam.userId = userId;
      $(_this.checkedFailureTypes).each(function (i, val) {
        if (_this.checkedFailureTypes.length == i + 1) {
          _this.addEmailParam.failureTypes += (val)
        } else {
          _this.addEmailParam.failureTypes += (val + ",")
        }

      })

      try {
        _this.$validator.validateAll("addEmailParam").then(async result => {
          if (result) {
            this.$http.post('/service-user/UserAlterEmailSend/addUserAlertEmailSend', _this.addEmailParam).then(function (res) {

              let data = res.data;

              if (data.status == 200) {
                //swal( this.$t('authority.StSonList.SwalEdit.SonTitleSuccess'), this.$t('authority.StSonList.SwalEdit.SonTextSuccess'), "success");
                swal({
                  //提示的标题
                  title: _this.$t('interfaceMsg.200'),
                  //提示的文本
                  text: _this.$t('alarm.msg.updateSuccessText'),
                  //类型：警告，还有success,error和info
                  type: "success",
                  //显示取消按钮
                  showCancelButton: false,
                  //确认按钮内文本
                  confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
                });
                _this.EmailAddInfoHide()

              }
              else {

                //swal( this.$t('authority.StSonList.SwalEdit.SonTitleErro'), this.$t('authority.StSonList.SwalEdit.SonTextErro'), 'error');
                swal({
                  //提示的标题
                  title: _this.$t("interfaceMsg." + data.status),
                  //提示的文本
                  text: _this.$t('interfaceMsg.500'),
                  //类型：警告，还有success,error和info
                  type: "error",
                  //显示取消按钮
                  showCancelButton: false,
                  //确认按钮内文本
                  confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
                });
              }

              _this.getUserAlertEmailSendList()
            })
          }
        })

      } catch (err) {

      }
    },


    /* 修改报警邮件  UserAlterEmailSend/updateUserAlertEmailSend */
    updateUserAlertEmailSend (userEmail) {
      var _this = this;
      if (_this.updateCheckedFailureTypes.length == 0) {
        swal({
          //提示的标题
          title: this.$t("alarm.msg.chooseCode"), //"报警类型至少勾选一项",
          //提示的文本
          // text: this.$t("login.msg.check"), //'请勾选我已阅读',
          //类型：警告，还有success,error和info
          type: "warning",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: this.$t("login.msg.determine") //'确定'
        });
        return false;
      }

      const userId = window.sessionStorage.getItem("userId");
      _this.updateEmailParam.userId = userId
      _this.updateEmailParam.customize = userEmail.customize
      _this.updateEmailParam.email = userEmail.email
      _this.updateEmailParam.userAlertId = userEmail.userAlertId
      _this.updateEmailParam.emailTipsId = userEmail.emailTipsId
      var checkTemp = "";
      $(_this.updateCheckedFailureTypes).each(function (i, val) {
        if (_this.updateCheckedFailureTypes.length == i + 1) {
          checkTemp += (val)
        } else {
          checkTemp += (val + ",")
        }

      })
      _this.updateEmailParam.failureTypes = checkTemp

      try {
        _this.$validator.validateAll("userEmail").then(async result => { })


        this.$http.post('/service-user/UserAlterEmailSend/updateUserAlertEmailSend', _this.updateEmailParam).then(function (res) {

          let data = res.data;

          if (data.status == 200) {
            //swal( this.$t('authority.StSonList.SwalEdit.SonTitleSuccess'), this.$t('authority.StSonList.SwalEdit.SonTextSuccess'), "success");
            swal({
              //提示的标题
              title: _this.$t('alarm.msg.updateSuccessTitle'),
              //提示的文本
              text: _this.$t('alarm.msg.updateSuccessText'),
              //类型：警告，还有success,error和info
              type: "success",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
            });
            _this.EmailAddInfoHide()

          } else {
            //swal( this.$t('authority.StSonList.SwalEdit.SonTitleErro'), this.$t('authority.StSonList.SwalEdit.SonTextErro'), 'error');
            swal({
              //提示的标题
              title: _this.$t("interfaceMsg." + data.status),
              //提示的文本
              text: _this.$t('interfaceMsg.500'),
              //类型：警告，还有success,error和info
              type: "error",
              //显示取消按钮
              showCancelButton: false,
              //确认按钮内文本
              confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
            });
          }
          _this.activeNames = []
          _this.getUserAlertEmailSendList()
        })
      } catch (err) { }


    },
    /* 删除报警邮件 /UserAlterEmailSend/deleteUserAlertEmail */
    deleteUserAlertEmail (userAlertId) {
      var _this = this;
      const userId = window.sessionStorage.getItem("userId");
      this.$http.get('/service-user/UserAlterEmailSend/deleteUserAlertEmail?userId=' + userId + '&userAlertId=' + userAlertId, {
        "userId": userId
      }).then(function (res) {
        let data = res.data;
        if (data.status == 200) {

          _this.getUserAlertEmailSendList()

        }
      })
    },

    /* 获取报警邮件 */
    getUserAlertEmailSendList () {
      var _this = this;
      const userId = window.sessionStorage.getItem("userId");
      this.$http.get('/service-user/UserAlterEmailSend/getUserAlertEmailSendList?userId=' + userId, {
        "userId": userId
      }).then(function (res) {
        let data = res.data;
        if (data.status == 200) {

          _this.userEmailList = data.data
          _this.updateList = data.data
          $(_this.updateList).each(function (i, val) {
            var tempCheck = [];
            if (val.failureTypes != "") {
              var failureTypesArr = val.failureTypes.split(',');
              $(failureTypesArr).each(function (x, type) {
                tempCheck.push(parseInt(type))
              })
            }
            val["tempCheck"] = tempCheck
            val["isSend"] = 0
          })

          console.log("_this.updateList", _this.updateList)

        } else if (data.status == 707) {
          _this.userEmailList = []
          _this.updateList = []
        }
      })
    },

    /* 获取报警故障类型 */
    getFailureTypes () {
      var _this = this;
      this.$http.get('/service-base/failureType/getFailureTypes', {}).then(function (res) {
        let data = res.data;
        _this.failureTypesOptions = []
        if (data.status == 200) {
          _this.failureTypes = data.data
          $.each(data.data, function (i, val) {
            _this.failureTypesOptions.push(val.failureTypeId)
          })

        }
      })
    },
    /* 获取报警故障类型 */
    getTips (email) {
      var tips;
      if (email.emailTipsId === 2) {
        tips = email.customize
      } else if (email.emailTipsId !== 1 && email.emailTipsId != null) {
        switch (this.$i18n.locale) {
          case "zh_CN":
            tips = email.eailTips.emailTipsMsgCn
            break;
          case "ja":
            tips = email.eailTips.emailTipsMsgJp
            break
          case "en":
            tips = email.eailTips.emailTipsMsgEn
            break
          default:
            tips = email.eailTips.emailTipsMsgCn
        }

      }
      return tips;
    },
    /* 获取报警故障类型 */
    getFailureTypeName (failureType) {
      var tips;

      switch (this.$i18n.locale) {
        case "zh_CN":
          tips = failureType.failureTypeNameCn
          break;
        case "ja":
          tips = failureType.failureTypeNameJp
          break
        case "en":
          tips = failureType.failureTypeNameEn
          break
        default:
          tips = failureType.failureTypeNameCn

      }
      return tips;
    },
    selCollapse (val) {
      this.getUserAlertEmailSendList()
      this.userAlertSubmit = false;
      if (val != "" && val != null) {
        this.updateCheckedFailureTypes = this.updateList[val].tempCheck
      }

    },
    sendTestMail (userEmail, index) {
      var _this = this;
      this.$delete(this.updateList[index], 'isSend')
      this.$set(this.updateList[index], 'isSend', 60)
      this.emailTimeOut(index)
      this.$http.get('/service-user/user/sendTestMail?email=' + userEmail.email + '&languageId=' + this.$i18n.locale, {}).then(function (res) {
        let data = res.data;
        if (data.status === 200) {
          //'邮件发送成功','请到注册邮箱中查收'
          swal({
            //提示的标题
            title: _this.$t("login.msg.sendSuccess"),
            //提示的文本
            text: _this.$t("login.msg.pleaseCheck"),
            //类型：警告，还有success,error和info
            type: "success",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("login.msg.determine") //'确定'
          });
        } else {
          swal({
            //提示的标题
            title: _this.$t("login.msg.sendError"),
            //提示的文本
            text: _this.$t("interfaceMsg.500"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: _this.$t("login.msg.determine") //'确定'
          });
        }

      })
    },
    emailTimeOut (index) {
      var _this = this;
      var tempTime = _this.updateList[index].isSend;
      var interval = setInterval(function () {
        tempTime -= 1
        _this.$delete(_this.updateList[index], 'isSend')
        _this.$set(_this.updateList[index], 'isSend', tempTime)
        if (tempTime == 0) {
          clearInterval(interval)
        }
      }, 1000)
    },
    showTips (id) {
      var _this = this;
      if (id === 4) {

        swal({
          //提示的标题
          title: _this.$t('alarm.tips'),
          //提示的文本
          text: '',
          //类型：警告，还有success,error和info
          type: "info",
          //显示取消按钮
          showCancelButton: false,
          //确认按钮内文本
          confirmButtonText: _this.$t("alarm.msg.confirm") //'确定'
        });

      }
    }
  }


}

</script>


<style>
.card-header {
  padding: 15px 10px 15px 10px !important;
}
.month,
.year {
  padding: 1.25rem 0.75rem;
}

</style>