<template>
  <!-- 月报表 -->

  <div class="calendar">
    <div class="calendar-head">
      <el-row>
        <el-col :label="$t('calendar.sunday')">{{$t('calendar.sunday')}}</el-col>
        <el-col :label="$t('calendar.monday')">{{$t('calendar.monday')}}</el-col>
        <el-col :label="$t('calendar.tuesday')">{{$t('calendar.tuesday')}}</el-col>
        <el-col :label="$t('calendar.wednesday')">{{$t('calendar.wednesday')}}</el-col>
        <el-col :label="$t('calendar.thursday')">{{$t('calendar.thursday')}}</el-col>
        <el-col :label="$t('calendar.friday')">{{$t('calendar.friday')}}</el-col>
        <el-col :label="$t('calendar.saturday')">{{$t('calendar.saturday')}}</el-col>
      </el-row>
    </div>
    <div class="calendar-body" style="padding:0rem">
      <el-row>
        <el-col v-for="(dayobject,index) in current.days" :key="index" style="padding:0.5rem 0.25rem">
          <template>
            <div :class="monthCss(dayobject)" v-if="dayobject.day.getFullYear() != 1970" :value="dayobject.day.getDate()">
              {{ dayobject.day.getDate() }}
              <ul class="siren">
                <div v-show="dayobject.data.failureNum != undefined && parseInt(dayobject.data.failureNum) != 0" class="malfunction">
                  <a href="javascript:void(0);" data-toggle="modal" data-target=".bd-SirenList-modal-xl" @click="backErrClick(dayobject,1)">
                    <li>
                      <div class="row clearfix">
                        <div class="col-8 col-left">
                          <i class="fad fa-tools" style="margin: 0 2px 0 1.5px"></i><span v-html="$t('calendar.guzhang')"></span>
                        </div>
                        <div class="col-4 text-right col-right"><span>{{dayobject.data.failureNum}}</span></div>
                      </div>
                    </li>
                  </a>
                </div>
                <div v-show="(dayobject.data.offlineNum != undefined) && parseInt(dayobject.data.offlineNum) != 0" class="offlinefunction">
                  <a href="javascript:void(0);" data-toggle="modal" data-target=".bd-SirenList-modal-xl" @click="backErrClick(dayobject,3)">
                    <li>
                      <div class="row clearfix">
                        <div class="col-8 col-left">
                          <i class="fad fa-sensor-alert"></i><span v-html="$t('calendar.lixian')"></span>
                        </div>
                        <div class="col-4 text-right col-right"><span>{{dayobject.data.offlineNum}}</span></div>
                      </div>
                    </li>
                  </a>
                </div>
                <div v-show="dayobject.data.abnormalNum != undefined && parseInt(dayobject.data.abnormalNum) != 0" class="abnormalcommunication">
                  <a href="javascript:void(0);" data-toggle="modal" data-target=".bd-SirenList-modal-xl" @click="backErrClick(dayobject,2)">
                    <li>
                      <div class="
                     row
                     clearfix">
                        <div class="col-8 col-left">
                          <i class="fad fa-comment-slash"></i><span v-html="$t('calendar.tongxuyichang')"></span>
                        </div>
                        <div class="col-4 text-right col-right"><span>{{dayobject.data.abnormalNum}}</span></div>
                      </div>
                    </li>
                  </a>
                </div>
                <!-- <li></li> -->
              </ul>
            </div>
            <div class="not-current" v-else>

            </div>
          </template>
        </el-col>
        <!-- <el-col class="today">
                      24
                    </el-col> -->

        <!-- <el-col class="not-current">
                      <span class="day">1</span>
                    </el-col> -->

      </el-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "monthCalendar",
  data () {
    return {
      psList: null,
      monthData: null,
      warningMonthList: [],//故障信息
      waningsKeyAllFlag: true,
      current: {
        today: new Date(),//当前日期
        currentDay: 1,
        currentMonth: 1,
        currentYear: 1970,
        currentWeek: 1,
        days: [], //用于装载日历展示的日期
        leftobj: []//用于装载页面显示的月份已经进行预约设置的数据
      },
      paraData: {
        psId: null,
        searchDate: new Date(),
      },
      //week: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      date: new Date(),
      calendar_month: [],
      //warnings:{'1':{info:{"failure":10,"offline_pmu":1,"offline_device":2,"stop_pmu":3,"stop_device":4}},"2":{info:{"failure":15,"offline_pmu":1,"offline_device":2,"stop_pmu":3,"stop_device":4}},"3":{info:{"offline_pmu":1}},"31":{info:{"offline_pmu":6,"stop_device":2}}},
    }
  },
  props: ["psId", "searchDate", "warningsAllType", "typeCalendar", "init"],

  watch: {
    psId: {
      handler (psId) {
        this.paraData.psId = psId;
        if (this.paraData.searchDate != null) {
          this.initData(new Date(this.formatDate(new Date(this.paraData.searchDate).getFullYear(), new Date(this.paraData.searchDate).getMonth() + 1, 1)))
        } else {
          this.initData(null);
        }
        // if (this.typeCalendar) {
        //   this.LoadData();
        // }
      }
    },
    searchDate: {
      handler (searchDate) {

        this.paraData.searchDate = searchDate;
        this.initData(searchDate);
        if (this.typeCalendar) {
          //数据读取
          this.LoadData();
        }
      }
    },
    init: {
      handler (init) {
        this.paraData.searchDate = new Date();
        this.initData(null);
        if (this.typeCalendar) {
          //数据读取
          this.LoadData();
        }
      }
    },
    warningsAllType: {
      handler (warningsAllType) {
        this.waningsKeyAllFlag = warningsAllType;

      }
    },
    typeCalendar: {
      handler (typeCalendar) {
        var _this = this;
        if (typeCalendar) {
          var d;
          if (_this.paraData.searchDate != null) {
            d = new Date(_this.paraData.searchDate);
          } else {
            d = new Date();
          }
          _this.initData(_this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
          _this.LoadData();
        }
      },

    }
  },
  created () {
    //this.initData(null);
  },
  mounted () {

  },
  methods: {
    //初始化当前页要展示的日期
    initData: function (cur) {
      var _this = this;
      var date;
      var num;     //展示页中日期的数目
      //控制预定设置相对于当前日期延后的数
      var index = 2;
      var today = new Date();
      _this.current.today = new Date(this.formatDate(today.getFullYear(), today.getMonth() + 1, today.getDate() + index));
      //定义正则表达式,表示大月
      var rep = /^(1|3|5|7|8|10|12)$/;
      if (cur) {
        date = new Date(cur);
        var monthIndex = date.getMonth() + 1;
        if ((date.getDay() == 0 && monthIndex != 2) || (date.getDay() == 6 && rep.test(monthIndex))) {
          num = 42;
        } else if (date.getDay() == 1 && monthIndex == 2 && (date.getFullYear() / 4 != 0)) {
          num = 28;
        } else {
          num = 35;
        }
      } else {
        var now = new Date();
        date = new Date(this.formatDate(now.getFullYear(), now.getMonth() + 1, 1));
        var monthIndex = date.getMonth() + 1;
        //通过判断计算出将本月所有天数全部展示的最小周数对应的天数
        if ((date.getDay() == 0 && monthIndex != 2) || (date.getDay() == 6 && rep.test(monthIndex))) {
          //要展示全改年改月的所有天数,需要6周即42天(一号为星期天且月份不为2月或者一号为星期六且月数为31天的月)
          num = 42;
        } else if (date.getDay() == 1 && monthIndex == 2 && (date.getFullYear() / 4 != 0)) {
          //要展示全改年改月的所有天数,需要4周即28天(一号为星期一且改月为平年的2月)
          num = 28;
        } else {
          //要展示全改年改月的所有天数,需要5周即35天(一号在星期六之前或则一号为星球六但月数为30天的月)
          num = 35;
        }
      }
      _this.current.currentDay = date.getDate();
      _this.current.currentYear = date.getFullYear();
      _this.current.currentMonth = date.getMonth() + 1;
      _this.current.currentWeek = date.getDay(); // //本月第一天是周几（周日0 周六 6）
      //将星期天的index数替换成7,便于后面就算



      if (num == 28 && (_this.current.currentWeek % 7) != 0) {
        num = num + (7 - (_this.current.currentWeek % 7)) + 1;
      }
      if (_this.current.currentWeek == 0) {
        _this.current.currentWeek = 7;
      }
      //格式化每个月的第一号
      var str = this.formatDate(_this.current.currentYear, _this.current.currentMonth, _this.current.currentDay);
      //清空days中的数据
      _this.current.days = [];

      //将每个月一号所在的位置确定并且补全第一周不是本月的日期
      // (确定一号所在的位置就可以就算出其他所有日期的位置)


      for (var i = _this.current.currentWeek; i >= 0; i--) {
        //获得这个月第一天的日期
        var d = new Date(str);
        //获得日历展示中上个月的日期
        d.setDate(d.getDate() - i);
        //创建一个json对象存放日期
        var dayobject = {};


        if (_this.current.currentWeek != 7) {
          if (d.getDate() != 1) {
            d.setFullYear(1970);
          }
          //将得到的日期复制给对象的day属性  
          dayobject.day = d;
          dayobject.data = {};
          //将对象添加到days集合中
          _this.current.days.push(dayobject);//将日期放入data 中的days数组 供页面渲染使用 
        } else if (_this.current.currentWeek == 7 && i == 0 && d.getDate() == 1) {
          dayobject.day = d;
          dayobject.data = {};
          _this.current.days.push(dayobject);//将日期放入data 中的days数组 供页面渲染使用 
        }

      }
      //console.info(num +":"+ _this.current.currentWeek)

      if (_this.current.currentWeek > 4 && rep.test(monthIndex)) {
        num = 42;
      }

      var MonthDayNum = (new Date(d.getFullYear(), (d.getMonth() + 1), 0).getDate()) - 1;

      //其他周
      for (var i = 1; i <= num - _this.current.currentWeek; i++) {
        //获得这个月第一天的日期
        var d = new Date(str);
        //获得日历展示本月一号后以及下个月的日期
        d.setDate(d.getDate() + i);
        //创建一个json对象存放日期
        if ((i - 1) >= MonthDayNum) {
          d.setFullYear(1970);
        }

        var dayobject = {};//dayobject   {day:date,index:2}
        //将得到的日期复制给对象的day属性
        dayobject.day = d;
        //将对象添加到days集合中
        dayobject.data = {};
        if (i < num - _this.current.currentWeek) {
          _this.current.days.push(dayobject);

        }
      }
    },
    //切换到当前月份的一号
    goCurrentMonth: function (year, month) {
      //获得今天的日期,用于得到今天的年份与月份
      var d = new Date();
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    //向前一个月
    pickPre: function (year, month) {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      var d = new Date(this.formatDate(year, month, 1));
      //将日期往前移动一天,用于得到上个月年份与月份
      d.setDate(0);
      //调用初始化日历的方法,并将日期设置为上个月的一号
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    //向后一个月
    pickNext: function (year, month) {
      var d = new Date(this.formatDate(year, month, 1));
      //将日期往后移动31天,用于得到下个月年份与月份
      d.setDate(32);//获取指定天之后的日期
      //调用初始化日历的方法,并将日期设置为下个月的一号
      this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    LoadData () {
      var _this = this;
      if (_this.paraData.searchDate == null || _this.paraData.searchDate == undefined || _this.paraData.searchDate == "") {
        _this.paraData.searchDate = new Date();
      }


      //('/error/getPsAlarmMonthDaysBypsId?psId='+_this.paraData.psId+'&searchDate='+_this.formatDate(_this.paraData.searchDate.getFullYear(), (_this.paraData.searchDate.getMonth() + 1), 1))
      //('http://47.101.155.162:8009/error/getPsAlarmMonthDaysBypsId?psId=EMSS21300000000&searchDate='+_this.formatDate(_this.paraData.searchDate.getFullYear(), (_this.paraData.searchDate.getMonth() + 1), 1))
      _this.$http.get('/service-device/api/error/getPsAlarmMonthDaysBypsId?psId=' + _this.paraData.psId + '&searchDate=' + _this.formatDate(_this.paraData.searchDate.getFullYear(), (_this.paraData.searchDate.getMonth() + 1), 1)).then(function (ret) {
        _this.monthData = ret.data;
        if (_this.monthData.status == 200 && _this.monthData.data != null && _this.monthData.data.length > 0) {

          //console.info(1111,(_this.current.days!= [] && _this.current.days.length>0 && _this.monthData != null))
          if (_this.current.days != [] && _this.current.days.length > 0) {
            for (var i = 0; i < _this.current.days.length; i++) {
              for (var j = 0; j < _this.monthData.data.length; j++) {
                let todaystr = new Date(_this.monthData.data[j].getdatetime);
                if (parseInt(_this.current.days[i].day.getFullYear()) != 1970 && parseInt(_this.current.days[i].day.getDate()) == todaystr.getDate()) {
                  //debugger;
                  _this.current.days[i].data = _this.monthData.data[j];
                }
              }
            }
          } else {
            if (_this.paraData.searchDate.getFullYear() == new Date().getFullYear() && _this.paraData.searchDate.getMonth() == new Date().getMonth()) {
              _this.initData(null);
            } else {
              _this.initData(_this.paraData.searchDate);
            }
          }
        }
        else {
          if (_this.paraData.searchDate.getFullYear() == new Date().getFullYear() && _this.paraData.searchDate.getMonth() == new Date().getMonth()) {
            _this.initData(null);
          } else {
            _this.initData(_this.paraData.searchDate);
          }
          //alert(_this.paraData.searchDate)

        }
      });

      //console.info("data",_this.monthData)
    },
    //当前月日期CSS控制
    monthCss (dayobject) {

      var mm_css = "other-month";
      let buildDate = window.sessionStorage.getItem("buildDate");
      //buildDate = new Date("2022-01-01")
      if (buildDate != null && buildDate != undefined) {
        //选择日期小于建电站日期,被禁用
        if (dayobject.day.getFullYear() < new Date(buildDate).getFullYear()) {
          mm_css = 'not-day';
        }
        if (dayobject.day.getFullYear() <= new Date(buildDate).getFullYear() && (dayobject.day.getMonth() + 1) < (new Date(buildDate).getMonth() + 1)) {
          mm_css = 'not-day';
        }

        if (dayobject.day.getFullYear() <= new Date(buildDate).getFullYear() && (dayobject.day.getMonth() + 1) <= (new Date(buildDate).getMonth() + 1) && dayobject.day.getDate() < new Date(buildDate).getDate()) {
          mm_css = 'not-day';
        }
      }

      //date.getFullYear()>=dayobject.day.getFullYear()&&date.getMonth()+1 <= (dayobject.day.getMonth()+1)&&date.getDate() <  dayobject.day.getDate()
      //当前日期小于等于选择的日期
      if (this.date.getFullYear() < dayobject.day.getFullYear()) {
        mm_css = 'not-day';
      }

      if (this.date.getFullYear() <= dayobject.day.getFullYear() && (this.date.getMonth() + 1) < (dayobject.day.getMonth() + 1)) {
        mm_css = 'not-day';
      }

      if (this.date.getFullYear() <= dayobject.day.getFullYear() && (this.date.getMonth() + 1) <= (dayobject.day.getMonth() + 1) && this.date.getDate() < dayobject.day.getDate()) {
        mm_css = 'not-day';
      }

      if (this.date.getFullYear() == dayobject.day.getFullYear() && (this.date.getMonth() + 1) == (dayobject.day.getMonth() + 1) && this.date.getDate() == dayobject.day.getDate()) {
        mm_css = mm_css + ' today';
      }

      return mm_css;

      //  if(this.date.getFullYear() <= dayobject.day.getFullYear()&&(this.date.getMonth()+1 <= dayobject.day.getMonth()+1)&&this.date.getDate() <  dayobject.day.getDate()){
      //    mm_css = mm_css +'not-day';
      // }else{
      //    mm_css = mm_css +'other-month';
      // }

      // if(this.date.getDate() == dayobject.day.getDate()&& (this.date.getMonth()+1) == (dayobject.day.getMonth()+1)){
      //   mm_css = mm_css +' today';
      // }
      // return mm_css;
    },
    //日历点击具体日期
    backErrClick (dayobject, errType) {
      this.$emit("backMonthCalendarClick", dayobject.day, errType, "month");
    }
  },

}
 </script>
 <style>
.ml-1,
.mx-1 {
  margin-left: 0rem !important;
}

.col-left {
  padding: 0rem 0rem 0rem 0.15rem;
}
.col-right {
  padding: 0rem 0.15rem 0rem 0rem;
}
</style>