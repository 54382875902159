<template>
  <!-- 告警页面 -->
  <!-- 告警详情弹框 data-backdrop="static" -->
  <div class="modal fade bd-SirenList-modal-xl"
       tabindex="-1"
       role="dialog"
       data-backdrop="static"
       aria-labelledby="mySmallModalLabel"
       id="errModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title h4"
              id="myLargeModalLabel">
            <span class="fa-layers fa-fw fa-2x">
              <i class="fad fa-siren-on"></i>
              <i class="fad fa-history"
                 data-fa-transform="shrink-9.5 up-1.8"></i>
            </span>
            <span v-html="$t('warningErrWindow.warninggjxx')"></span>
          </h5>
          <el-date-picker v-model="warningPicker.selectDate"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          style="width: 40%;margin-left:20px;"
                          @change="changeDatePicker"
                          type="date"
                          :clearable="false"
                          :placeholder="$t('warningErrWindow.warningxzrq')"
                          :picker-options="warningPicker.pickerOptions" />
          <button class="close"
                  data-dismiss="modal"
                  @click="closeErrWin">
            <span aria-hidden="true">× </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="choose-neck">
            <el-form>
              <div class="col-md-12 para_window"
                   v-show="IsDisabled"></div>
              <el-form-item :label="$t('warningErrWindow.warninggjlx')">
                <GroupSirenType :cls="'col-12'"
                                :LanguageId="languageId"
                                :SelFailureTypeId="warningProps.errType"
                                @subWaningsKey="handleSubWaningsKey" />
              </el-form-item>
              <el-form-item v-show="IsDevDisabled"
                            :label="$t('warningErrWindow.warningsblx')"
                            class="m-t--20"
                            style="margin-top:5px;">
                <group-device-type :cls="'col-12'"
                                   :psId="warningProps.psId"
                                   :winopen="warningProps.winopen"
                                   @deviceTypeIdChecked="handleDeviceTypeChange" />
              </el-form-item>
            </el-form>
          </div>
          <div class="son-body m-t--15">
            <div class="table-responsive table-body-sm">
              <el-table :data="jsonDataList.rows"
                        v-loading="load"
                        :empty-text="$t('warningErrWindow.warningwcxdxgsj')"
                        height="418">
                <el-table-column :label="$t('warningErrWindow.warningriqi')"
                                 :render-header="(h,obj) => ActiveDay(h,obj,warningPicker.selectDate)">
                  <el-table-column :prop="psName"
                                   :label="$t('warningErrWindow.warningxzdz')"
                                   min-width="220">
                    <template slot="header">
                      <!-- "随机数："+{{resetNum}}+"弹框随机数:"+{{warningProps.monthWinopen}} -->
                      <select-ps-id-view :cls="'col-12'"
                                         :allType=true
                                         storeStatus=false
                                         :psList="psList"
                                         :inInput=filterable
                                         @psIdChange="handlePsIdChange" />
                    </template>
                    <template slot-scope="scopename">
                      <i class="fal fa-home-alt"></i>
                      <span class="ml-1">{{
                           scopename.row.psName
                          }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="typeCalendar&&warningProps.errType !== 2"
                                   :label="$t('warningErrWindow.warningsbmc')"
                                   min-width="80">
                    <template slot-scope="scopename">
                      <span class="ml-1">{{
                           scopename.row.deviceName || '-'
                          }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('warningErrWindow.warningdzm')"
                                   min-width="50" v-if="warningProps.errType !== 2">
                    <template slot-scope="scopename">
                      <span class="ml-1">{{
                           scopename.row.deviceAddress || '-'
                          }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="!typeCalendar&&warningProps.errType !== 2"
                                   :label="$t('warningErrWindow.warningsblxmc')"
                                   min-width="120">
                    <template slot-scope="scopename">
                      <span class="ml-1">{{
                           scopename.row.deviceTypeName || '-'
                          }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('warningErrWindow.warningneirong')" min-width="80">
                    <template slot-scope="scopename">
                      <span class="ml-1"
                            v-show="warningProps.errType == 1">{{
                           scopename.row.failureName || '-'
                      }}</span>
                      <span class="ml-1"
                            v-show="warningProps.errType == 3">{{
                        $t('calendar.lixian')
                      }}</span>
                      <span class="ml-1"
                            v-show="warningProps.errType == 2">{{
                         $t('calendar.tongxuyichang')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('warningErrWindow.warningfssj2')" min-width="90">

                    <template slot-scope="scopetime">
                      <i class="el-icon-time"></i>
                      <span class="ml-1"
                            v-show="warningProps.errType == 1">{{
                          formatterTime(scopetime.row.getdatetime)
                          }}</span>
                      <span class="ml-1"
                            v-show="warningProps.errType == 2 || warningProps.errType == 3 ">{{
                          formatterTime(scopetime.row.offlineTime)
                          }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column min-width="90"
                                   :label="$t('warningErrWindow.warningfssj')">
                    <template slot-scope="scopetime">
                      <i class="el-icon-time"></i>
                      <span class="ml-1"
                            v-show="warningProps.errType == 1">{{
                           formatterTime(scopetime.row.lastTime) 
                          }}</span>
                      <span class="ml-1"
                            v-show="warningProps.errType == 2 || warningProps.errType == 3 ">{{
                          formatterTime(scopetime.row.recoveryTime)
                          }}</span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table>
              <el-pagination background
                             v-if="jsonDataList.page>0"
                             class="text-center"
                             layout="prev, pager, next"
                             :page-size="err_pagination.pageSize"
                             :current-page="err_pagination.currentPage"
                             @current-change="current_change"
                             :total="err_pagination.total">
              </el-pagination>
              <!-- <el-table :data="SirenTableData" v-loading="loadingPage" empty-text="未查询到相关数据" height="310">
                    <el-table-column label="日期" :render-header="ActiveDay">
                      <el-table-column prop="StationId" label="选择电站" width="220">
                        <template slot="header">
                          <select-ps-id-view :cls="'col-12'" @psIdChange="handlePsIdChange" />
                        </template>
                        <template slot-scope="scopeid">
                          <i class="fal fa-home-alt"></i>
                          <span class="ml-1">{{ scopeid.row.StationId }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="EquipmentName" label="设备名" />
                      <el-table-column prop="AdressNumber" label="地址码" />
                      <el-table-column prop="SirenInfo" label="内容" />
                      <el-table-column prop="SirenTime" label="发生时间">
                        <template slot-scope="scopetime">
                          <i class="el-icon-time"></i>
                          <span class="ml-1">{{ scopetime.row.SirenTime }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                  </el-table> -->
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                  @click="closeErrWin"
                  v-html="$t('warningErrWindow.warningguanbi')">
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import GroupSirenType from "../AlarmView/GroupSirenType.vue";
import SelectPsIdView from "../AlarmView/SelectPsId.vue";
import GroupDeviceType from "../HistoryView/GroupDeviceType.vue";

export default {
  components: { SelectPsIdView, GroupSirenType, GroupDeviceType },
  name: 'warningErrWindow',
  props: ["monthWinopen",
    "yearWinopen",
    "psId",
    "typeCalendar",
    "errDate",
    "errType",
    "selectPsName",
    "clkType"
  ],
  created () {
    //this.getPsInfos();
  },
  mounted () {

  },
  data () {
    return {
      IsDisabled: false,
      IsDevDisabled: true,
      failureList: [],
      selFailureTypeId: 1,
      psRushFlag: false,
      bntNumber: "",
      resetNum: '',
      languageId: '2',
      psName: "",
      load: true,
      psList: null,
      filterable: true,
      //日期选择器
      valueson1: "",
      checkedDeviceTypeId: null,
      warningPicker: {
        selectYear: null,
        selectMonth: null,
        selectDate: new Date(),
        pickerOptions: {
          disabledDate: this.errDisabledDate
        }
      },
      warningProps: {
        psId: null,
        psName: null,
        typeCalendar: null,
        errDate: null,
        errType: null,
        deviceTypeId: 0,
        monthWinopen: "",
        yearWinopen: "",
      },
      jsonDataList: [],
      err_pagination: {
        total: 0,
        pageSize: 10,//每页的数据条数
        currentPage: 1,//默认开始页面
      },
      selectPsId: "",
    }
  },
  watch: {
    psId: {
      handler (psId) {
        this.warningProps.psId = psId;
      }
    },
    selectPsName: {
      handler (selectPsName) {
        this.warningProps.psName = selectPsName;
      }
    },
    typeCalendar: {
      handler (typeCalendar) {
        this.warningProps.typeCalendar = typeCalendar;
      },
    },
    errDate: {
      handler (errDate) {
        //console.info("errDate", errDate)
        if (errDate != null) {
          this.warningPicker.selectDate = errDate;

        }
      },
    },
    errType: {
      handler (errType) {
        this.warningProps.errType = errType;
      }
    },
    monthWinopen: {
      handler (monthWinopen) {
        var _this = this;
        if (monthWinopen != "") {
          this.resetNum = Math.floor(Math.random() * 999999);
          this.err_pagination.currentPage = 1;
          this.$store.state.isload = true;
          this.warningProps.monthWinopen = monthWinopen
          let locale = this.$validator.locale
          if (this.$store.state.languageId == null) {
            this.$store.state.languageId = locale
          }

          switch (this.$store.state.languageId) {
            case "zh_CN":
              this.languageId = 1
              break;
            case "ja":
              this.languageId = 2
              break
            case "en":
              this.languageId = 3
              break
            default:
              this.languageId = 2
          }
          this.getWarningType();
        }
      },
    },
    yearWinopen: {
      handler (yearWinopen) {
        if (yearWinopen != "") {
          this.resetNum = Math.floor(Math.random() * 999999);
          this.err_pagination.currentPage = 1;
          this.$store.state.isload = true;
          this.warningProps.yearWinopen = yearWinopen
          let locale = this.$validator.locale
          if (this.$store.state.languageId == null) {
            this.$store.state.languageId = locale
          }
          switch (this.$store.state.languageId) {
            case "zh_CN":
              this.languageId = 1
              break;
            case "ja":
              this.languageId = 2
              break
            case "en":
              this.languageId = 3
              break
            default:
              this.languageId = 2
          }
          this.getWarningType();
        }
      }
    },
    clkType: {
      handler (clkType) {

      }
    }
  },
  methods: {
    init () {
      this.warningPicker.selectDate = new Date();
    },
    //电站切换
    handlePsIdChange (psBuilddate, psId, psName) {
      var _this = this;
      //_this.psId = psId;
      _this.err_pagination.currentPage = 1;

      //_this.current.currentYear = new Date().getFullYear();
      //_this.current.currentMonth = new Date().getMonth() + 1;
      if (psBuilddate != null) {
        window.sessionStorage.setItem("buildDate", psBuilddate)
      } else {
        window.sessionStorage.setItem("buildDate", null)
      }
      _this.warningProps.psId = psId
      _this.warningProps.psName = psName;
      if (this.psRushFlag) {//防止弹框和电站列表二次调用报警数据接口
        this.getWarningType();
      }
      this.psRushFlag = true;
      //this.init();
      this.$emit("parentPsId");
    },
    //切换选中的设备类型
    handleDeviceTypeChange (deviceTypeId) {
      this.err_pagination.currentPage = 1;
      if (deviceTypeId == undefined || deviceTypeId == "" || deviceTypeId == null) {
        deviceTypeId = 0;
      }
      if ("loading" == deviceTypeId) {
        //this.checkedDeviceTypeId = "loading," + temp;
        this.warningProps.deviceTypeId = 0;
      } else {
        this.warningProps.deviceTypeId = deviceTypeId;
        this.checkedDeviceTypeId = deviceTypeId;
      }
      if ((this.warningProps.monthWinopen != "" || this.warningProps.yearWinopen != "") && this.warningProps.errType != 2) {
        this.getWarningType();
      }
    },
    handleSubWaningsKey: function (selFailureTypeId) {
      this.err_pagination.currentPage = 1;
      this.warningProps.selFailureTypeId = selFailureTypeId;
      this.warningProps.errType = selFailureTypeId;
      if (this.warningProps.winopen != "") {
        this.getWarningType();
      }

    },
    //故障历史日明细控件选择范围
    errDisabledDate: (time) => {

      let buildDate = window.sessionStorage.getItem("buildDate");
      if (buildDate != "null") {
        let dts = buildDate.split("-");
        let dt = new Date(parseInt(dts[0]), parseInt(dts[1]) - 1, parseInt(dts[2]));
        return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
      } else {
        let dt = new Date();
        return time.getTime() >= Date.now() || time.getTime() <= dt.getTime();
      }
    },
    //切换月日历
    changeDatePicker () {
      //alert(this.warningPicker.selectDate)
      this.warningPicker.selectDate = new Date(this.warningPicker.selectDate)
      if (this.warningPicker.selectDate.getFullYear() == 1970) {
        this.warningPicker.selectDate = new Date();
      }
      this.getWarningType();
    },
    ActiveDay (h, { column, $index }, selectDate) {
      //console.info("selectDate",selectDate)
      return h('span', {}, [
        h('span', { class: "x-large" }, [
          h('span', { class: 'x-large' }, selectDate.getFullYear()),
          h('i', { class: 'fad fa-scrubber xx-small ml-1' }, ''),
          h('span', { class: 'x-large' }, (selectDate.getMonth() + 1) < 10 ? "0" + (selectDate.getMonth() + 1) : (selectDate.getMonth() + 1)),
          h('i', { class: 'fad fa-scrubber xx-small ml-1' }, ''),
          h('span', { class: 'x-large' }, (selectDate.getDate()) < 10 ? "0" + (selectDate.getDate()) : (selectDate.getDate()))
        ])
      ])
    },
    current_change: function (currentPage) {
      this.err_pagination.currentPage = currentPage;
      this.getWarningType();
    },
    //查询故障列表
    warningErrList () {
      var _this = this;
      _this.load = true;
      if (this.warningPicker.selectDate != null && this.warningPicker.selectDate != "" && this.warningPicker.selectDate.getFullYear() != 1970) {
        //this.$http.get('/service-warning/warning/getCustomErrorDetail?psId=' + this.warningProps.psId + '&searchDate=' + this.warningProps.errDate + '&deviceTypeId=' + this.warningProps.deviceTypeId)
        const userId = window.sessionStorage.getItem("userId");
        const selectDate = _this.formatDate(this.warningPicker.selectDate.getFullYear(), this.warningPicker.selectDate.getMonth() + 1, this.warningPicker.selectDate.getDate());
        // http://47.101.155.162:7070/api/service-warning/warning/getCustomErrorDetail?currentPage=1&pageCount=10&userId=' + userId + '&psId=' + _this.warningProps.psId + '&searchDate=' + selectDate + '&deviceTypeId=' + _this.warningProps.deviceTypeId
        _this.jsonDataList = [];
        _this.$http.get('/service-warning/warning/getCustomErrorDetail?currentPage=' + _this.err_pagination.currentPage + '&pageCount=' + _this.err_pagination.pageSize + '&userId=' + userId + '&psId=' + _this.warningProps.psId + '&searchDate=' + selectDate + '&deviceTypeId=' + _this.warningProps.deviceTypeId + '&languageId=' + _this.languageId).then(function (ret) {
          //_this.jsonDataList = { "status": 200, "msg": "", "success": true, "data": { "page": 0, "total": 0, "records": 0, "rows": [] } }
          if (ret.status == 200 && ret.data.data != null) {
            _this.jsonDataList = ret.data.data;
            _this.err_pagination.total = _this.jsonDataList.records;

            for (var i = 0; i < _this.jsonDataList.length; i++) {
              if (_this.jsonDataList[i].lastTime.indexOf(' ') >= 0) {
                _this.jsonDataList[i].lastTime = _this.jsonDataList[i].lastTime.split(' ')[1]
              }
            }
            //console.info("jsonDataList", _this.jsonDataList)
          }
          _this.load = false;
          _this.IsDisabled = false;
        });
      }

    },
    //查询离线列表
    warningOffLineList () {
      var _this = this;
      _this.load = true;
      if (this.warningPicker.selectDate != null && this.warningPicker.selectDate != "" && this.warningPicker.selectDate.getFullYear() != 1970) {
        //this.$http.get('/service-warning/warning/getCustomErrorDetail?psId=' + this.warningProps.psId + '&searchDate=' + this.warningProps.errDate + '&deviceTypeId=' + this.warningProps.deviceTypeId)
        const userId = window.sessionStorage.getItem("userId");
        const selectDate = _this.formatDate(this.warningPicker.selectDate.getFullYear(), this.warningPicker.selectDate.getMonth() + 1, this.warningPicker.selectDate.getDate());
        // http://47.101.155.162:7070/api/service-warning/warning/getCustomErrorDetail?currentPage=1&pageCount=10&userId=' + userId + '&psId=' + _this.warningProps.psId + '&searchDate=' + selectDate + '&deviceTypeId=' + _this.warningProps.deviceTypeId
        _this.jsonDataList = [];
        _this.$http.get('/service-warning/warning/getEquipmentOfflineDetail?currentPage=' + _this.err_pagination.currentPage + '&pageCount=' + _this.err_pagination.pageSize + '&userId=' + userId + '&psId=' + _this.warningProps.psId + '&searchDate=' + selectDate + '&deviceTypeId=' + _this.warningProps.deviceTypeId).then(function (ret) {
          if (ret.status == 200 && ret.data.data != null) {
            _this.jsonDataList = ret.data.data;
            _this.err_pagination.total = _this.jsonDataList.total;
          }
          _this.load = false;
          _this.IsDisabled = false;
        });
      }
    },
    //查询通讯异常列表
    warningAbnormalCommunicationList () {
      var _this = this;
      _this.load = true;
      if (this.warningPicker.selectDate != null && this.warningPicker.selectDate != "" && this.warningPicker.selectDate.getFullYear() != 1970) {
        //this.$http.get('/service-warning/warning/getCustomErrorDetail?psId=' + this.warningProps.psId + '&searchDate=' + this.warningProps.errDate + '&deviceTypeId=' + this.warningProps.deviceTypeId)
        const userId = window.sessionStorage.getItem("userId");
        const selectDate = _this.formatDate(this.warningPicker.selectDate.getFullYear(), this.warningPicker.selectDate.getMonth() + 1, this.warningPicker.selectDate.getDate());
        // http://47.101.155.162:7070/api/service-warning/warning/getCustomErrorDetail?currentPage=1&pageCount=10&userId=' + userId + '&psId=' + _this.warningProps.psId + '&searchDate=' + selectDate + '&deviceTypeId=' + _this.warningProps.deviceTypeId
        _this.jsonDataList = [];
        _this.$http.get('/service-warning/warning/getAbnormalCommunicationDetail?currentPage=' + _this.err_pagination.currentPage + '&pageCount=' + _this.err_pagination.pageSize + '&userId=' + userId + '&psId=' + _this.warningProps.psId + '&searchDate=' + selectDate).then(function (ret) {
          if (ret.status == 200 && ret.data.data != null) {
            _this.jsonDataList = ret.data.data;
            _this.err_pagination.total = _this.jsonDataList.total;
          }
          _this.load = false;
          _this.IsDisabled = false;
        });
      }
    },
    getWarningType () {
      if (this.warningProps.errType != 2) {
        this.IsDevDisabled = true;
      } else {
        this.IsDevDisabled = false;
      }
      this.IsDisabled = true;
      switch (this.warningProps.errType) {
        case 1://故障
          this.warningErrList();
          break;
        case 2://通讯异常
          this.warningAbnormalCommunicationList();
          break;
        case 3://设备离线
          this.warningOffLineList();
          break;
      }
    },
    //转换时间格式
    formatterTime (dateTime) {
      if (dateTime != null && dateTime != undefined && dateTime != "") {
        return this.$moment(dateTime).format("HH:mm:ss")
      } else {
        return "-"
      }

    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    closeErrWin () {
      //this.err_pagination.currentPage = 1;
      this.psRushFlag = false;//防止弹框和电站列表二次调用报警数据接口
      this.$store.state.isload = false;
      this.errDate = "";
      this.$emit("backFailureTypeIdClick", this.warningProps.errType);
    }
  }
}

</script>

<style>
.para_window {
  display: block;
  margin: auto;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 576px){
   .modal-dialog {
    width: 700px !important;
    margin: 1.75rem auto;
   }
}
</style>