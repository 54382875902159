<template>
  <!-- 年报表 -->
  <div class="almanac">
    <div class="almanac-body">

      <div class="col-lg-12">
        <div class="row clearfix">
          <!-- 1月 -->
          <div class="col-md-3"
               v-for="(monobject,index) in current.yearWarningArr"
               :key="index">
            <!-- :shadow="{((date.getMonth()+1) == parseInt(monobject.monkey))?'never':'hover')}" -->
            <el-card :shadow="shadowVal(monobject)">
              <div class="almanac-son-header"><span><span class="x-large"
                        :value="monobject.monkey">{{monobject.monkey}}</span><span v-html="$t('alarm.alarmmonth')"></span></span></div>
              <div class="almanac-son-body">
                <div class="calendar-head">
                  <el-row>
                    <el-col :text="$t('calendar.sunday')">{{$t('calendar.sunday')}}</el-col>
                    <el-col :text="$t('calendar.monday')">{{$t('calendar.monday')}}</el-col>
                    <el-col :text="$t('calendar.tuesday')">{{$t('calendar.tuesday')}}</el-col>
                    <el-col :text="$t('calendar.wednesday')">{{$t('calendar.wednesday')}}</el-col>
                    <el-col :text="$t('calendar.thursday')">{{$t('calendar.thursday')}}</el-col>
                    <el-col :text="$t('calendar.friday')">{{$t('calendar.friday')}}</el-col>
                    <el-col :text="$t('calendar.saturday')">{{$t('calendar.saturday')}}</el-col>
                  </el-row>
                </div>
                <div class="calendar-body">
                  <el-row :class="{'siren-all':warningsAllType}">
                    <el-col v-for="(dayobject,index) in monobject.monval"
                            :key="index">
                      <a href="javascript:void(0);"
                         @click="backErrClick(dayobject)"
                         data-toggle="modal"
                         data-target=".bd-SirenList-modal-xl"
                         v-show="showErrCount(dayobject)">
                        <el-tooltip placement="bottom"
                                    popper-class="dark_aooltip">
                          <div slot="content">
                            <span v-show="dayobject.data.failureNum != undefined && dayobject.data.failureNum>0"><span><span v-html="$t('calendar.guzhang')"></span>：{{dayobject.data.failureNum}}</span><br /></span>
                            <span v-show="dayobject.data.offlineNum != undefined && dayobject.data.offlineNum>0"><span><span v-html="$t('calendar.lixian')"></span>：{{dayobject.data.offlineNum}}</span><br /></span>
                            <span v-show="dayobject.data.abnormalNum != undefined && dayobject.data.abnormalNum>0"><span><span v-html="$t('calendar.tongxuyichang')"></span>：{{dayobject.data.abnormalNum}}</span><br /></span>
                          </div>
                          <!-- <div class="number-round is-active">1</div> -->
                          <div class="number-round is-active"
                               :value="dayobject.day.getDate()">
                            {{dayobject.day.getDate()   }}
                          </div>
                        </el-tooltip>
                      </a>
                      <div class="number-round"
                           :class="{'not-day':showNotday(dayobject)}"
                           v-show="!showErrCount(dayobject)"> {{dayobject.day.getDate()}}</div>
                    </el-col>
                    <!-- <el-col>
                                  <div class="number-round">2</div>
                                </el-col>
                                <el-col class="not-current">
                                </el-col>
                                <el-col class="not-current">
                                </el-col> -->
                  </el-row>
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>


export default {
  name: "yearCalendar",
  data () {
    return {
      yearData: null,
      waningsKeyAllFlag: true,
      current: {
        today: new Date(),//当前日期
        currentDay: 1,
        currentMonth: 1,
        currentYear: 1970,
        currentWeek: 1,
        days: [], //用于装载日历展示的日期
        leftobj: [],//用于装载页面显示的月份已经进行预约设置的数据
        yearWarningArr: [],//存放一年的预警数据量
      },
      paraData: {
        psId: null,
        searchDate: new Date(),
      },
      date: new Date()
    }
  },
  props: ["psId", "searchDate", "warningsAllType", "typeCalendar", "init"],
  watch: {
    psId: {
      handler (psId) {
        this.paraData.psId = psId;
        if (this.paraData.searchDate != null) {
          this.initYearData(new Date(this.paraData.searchDate).getFullYear());
        } else {
          this.initYearData(new Date().getFullYear());
        }
        if (!this.typeCalendar) {
          this.LoadData();
        }
      }
    },
    searchDate: {
      handler (searchDate) {
        this.paraData.searchDate = searchDate;
        this.initYearData(new Date(searchDate).getFullYear());
        if (!this.typeCalendar) {
          this.LoadData();
        }
      }
    },
    init: {
      handler (init) {
        this.paraData.searchDate = new Date();
        this.initYearData(new Date().getFullYear());
        if (!this.typeCalendar) {
          this.LoadData();
        }
      }
    },
    warningsAllType: {
      handler (warningsAllType) {
        this.waningsKeyAllFlag = warningsAllType;

      }
    },
    typeCalendar: {
      handler (typeCalendar) {
        if (!typeCalendar) {
          this.initYearData(new Date(this.paraData.searchDate).getFullYear());
          this.LoadData();
        }
      }
    }
  },
  created () {
    this.initYearData(this.current.today.getFullYear());
  },
  mounted () {

  },
  methods: {
    //初始化当前页要展示的日期
    initMonthData: function (cur) {
      //debugger;
      var _this = this;
      var date;
      var num;     //展示页中日期的数目
      //控制预定设置相对于当前日期延后的数
      var index = 2;
      var today = new Date();
      _this.current.today = new Date(this.formatDate(today.getFullYear(), today.getMonth() + 1, today.getDate() + index));
      //定义正则表达式,表示大月
      var rep = /^(1|3|5|7|8|10|12)$/;
      if (cur) {
        date = new Date(cur);
        var monthIndex = date.getMonth() + 1;
        if ((date.getDay() == 0 && monthIndex != 2) || (date.getDay() == 6 && rep.test(monthIndex))) {
          num = 42;
        } else if (date.getDay() == 1 && monthIndex == 2 && (date.getFullYear() / 4 != 0)) {
          num = 28;
        } else {
          num = 35;
        }
      } else {
        var now = new Date();
        date = new Date(this.formatDate(now.getFullYear(), now.getMonth() + 1, 1));
        var monthIndex = date.getMonth() + 1;
        //通过判断计算出将本月所有天数全部展示的最小周数对应的天数
        if ((date.getDay() == 0 && monthIndex != 2) || (date.getDay() == 6 && rep.test(monthIndex))) {
          //要展示全改年改月的所有天数,需要6周即42天(一号为星期天且月份不为2月或者一号为星期六且月数为31天的月)
          num = 42;
        } else if (date.getDay() == 1 && monthIndex == 2 && (date.getFullYear() / 4 != 0)) {
          //要展示全改年改月的所有天数,需要4周即28天(一号为星期一且改月为平年的2月)
          num = 28;
        } else {
          //要展示全改年改月的所有天数,需要5周即35天(一号在星期六之前或则一号为星球六但月数为30天的月)
          num = 35;
        }
      }
      _this.current.currentDay = date.getDate();
      _this.current.currentYear = date.getFullYear();
      _this.current.currentMonth = date.getMonth() + 1;
      _this.current.currentWeek = date.getDay(); // //本月第一天是周几（周日0 周六 6）
      //将星期天的index数替换成7,便于后面就算



      if (num == 28 && (_this.current.currentWeek % 7) != 0) {
        num = num + (7 - (_this.current.currentWeek % 7)) + 1;
      }
      if (_this.current.currentWeek == 0) {
        _this.current.currentWeek = 7;
      }
      //格式化每个月的第一号
      var str = this.formatDate(_this.current.currentYear, _this.current.currentMonth, _this.current.currentDay);
      //清空days中的数据
      _this.current.days = [];

      //将每个月一号所在的位置确定并且补全第一周不是本月的日期
      // (确定一号所在的位置就可以就算出其他所有日期的位置)


      for (var i = _this.current.currentWeek; i >= 0; i--) {
        //获得这个月第一天的日期
        var d = new Date(str);
        //获得日历展示中上个月的日期
        d.setDate(d.getDate() - i);
        //创建一个json对象存放日期
        var dayobject = {};


        if (_this.current.currentWeek != 7) {
          if (d.getDate() != 1) {
            d.setFullYear(1970);
          }
          //将得到的日期复制给对象的day属性  
          dayobject.day = d;
          dayobject.data = {};
          //将对象添加到days集合中
          _this.current.days.push(dayobject);//将日期放入data 中的days数组 供页面渲染使用 
        } else if (_this.current.currentWeek == 7 && i == 0 && d.getDate() == 1) {
          dayobject.day = d;
          dayobject.data = {};
          _this.current.days.push(dayobject);//将日期放入data 中的days数组 供页面渲染使用 
        }

      }
      //console.info(num +":"+ _this.current.currentWeek)

      if (_this.current.currentWeek > 4 && rep.test(monthIndex)) {
        num = 42;
      }

      var MonthDayNum = (new Date(d.getFullYear(), (d.getMonth() + 1), 0).getDate()) - 1;

      //其他周
      for (var i = 1; i <= num - _this.current.currentWeek; i++) {
        //获得这个月第一天的日期
        var d = new Date(str);
        //获得日历展示本月一号后以及下个月的日期
        d.setDate(d.getDate() + i);
        //创建一个json对象存放日期
        if ((i - 1) >= MonthDayNum) {
          d.setFullYear(1970);
        }

        var dayobject = {};//dayobject   {day:date,index:2}
        //将得到的日期复制给对象的day属性
        dayobject.day = d;
        //将对象添加到days集合中
        dayobject.data = {};
        if (i < num - _this.current.currentWeek) {
          _this.current.days.push(dayobject);

        }
      }
      var yearWarning = {};
      yearWarning.monkey = _this.current.currentMonth;
      yearWarning.monval = _this.current.days;
      _this.current.yearWarningArr.push(yearWarning);
      //console.info(_this.current.yearWarningArr)
    },
    initYearData: function (year) {
      this.current.yearWarningArr = [];
      this.current.currentYear = year;
      if (this.current.currentYear == 1970) {
        this.current.currentYear = new Date().getFullYear();
      }

      for (var i = 1; i <= 12; i++) {
        this.initMonthData(new Date(this.formatDate(this.current.currentYear, i, 1)));
      }
      //console.info("yearWarningArr",this.current.yearWarningArr)
    },
    LoadData () {
      var _this = this;
      if (_this.paraData.searchDate == null || _this.paraData.searchDate == undefined || _this.paraData.searchDate == "") {
        _this.paraData.searchDate = new Date();
      }
      //('http://47.101.155.162:8009/error/getPsAlarmYearMonthsBypsId?psId=EMSS21300000000&searchDate='+_this.formatDate(_this.paraData.searchDate.getFullYear(),1, 1))
      //('/error/getPsAlarmYearMonthsBypsId?psId='+_this.paraData.psId+'&searchDate='+_this.formatDate(_this.paraData.searchDate.getFullYear(),1, 1))
      _this.$http.get('/service-device/api/error/getPsAlarmYearMonthsBypsId?psId=' + _this.paraData.psId + '&searchDate=' + _this.formatDate(_this.paraData.searchDate.getFullYear(), 1, 1)).then(function (ret) {
        let data = ret.data;
        if (data.status == 200) {
          _this.yearData = ret.data;

          if (_this.current.yearWarningArr != [] && _this.current.yearWarningArr.length > 0 && _this.yearData != null) {
            for (var i = 0; i < _this.current.yearWarningArr.length; i++) {
              for (var j = 0; j < _this.current.yearWarningArr[i].monval.length; j++) {
                for (var k = 0; k < _this.yearData.data.length; k++) {
                  let todaystr = new Date(_this.yearData.data[k].getdatetime);
                  //不是1970年和getdatetime日期一致的绑定当日故障数
                  if (parseInt(_this.current.yearWarningArr[i].monval[j].day.getFullYear()) != 1970 && todaystr.getDate() == parseInt(_this.current.yearWarningArr[i].monval[j].day.getDate()) && ((todaystr.getMonth() + 1) == (_this.current.yearWarningArr[i].monval[j].day.getMonth()) + 1)) {
                    _this.current.yearWarningArr[i].monval[j].data = _this.yearData.data[k];
                  }
                }
              }

            }
            //  for(var i=0;i<_this.current.days.length;i++){
            //     for(var j=0;j< _this.monthData.data.length;j++){
            //        let todaystr = new Date(_this.monthData.data[j].getdatetime);
            //        if(parseInt(_this.current.days[i].day.getFullYear()) != 1970&& parseInt(_this.current.days[i].day.getDate()) == todaystr.getDate()){
            //           //debugger;
            //           _this.current.days[i].data = _this.monthData.data[j];
            //        }
            //     }
            //  }
          }
        }
        else {
          data = null;
        }
      });
    },
    showErrCount(dayobject){
       let showErrFlag = false;
       if(dayobject.data.failureNum != undefined && dayobject.data.failureNum>0){
          showErrFlag = true;
       }
       if(dayobject.data.offlineNum != undefined && dayobject.data.offlineNum>0){
          showErrFlag = true;
       }
       if(dayobject.data.abnormalNum != undefined && dayobject.data.abnormalNum>0){
          showErrFlag = true;
       }
       return showErrFlag;
      //  if(dayobject.day.getFullYear()!=1970){

      //  }
    },
    showNotday (dayobject) {

      let buildDate = window.sessionStorage.getItem("buildDate");
      //buildDate = new Date("2022-01-01")
      if (buildDate != null && buildDate != undefined) {
        //选择日期小于建电站日期,被禁用
        if (dayobject.day.getFullYear() < new Date(buildDate).getFullYear()) {
          return true;
        }
        if (dayobject.day.getFullYear() <= new Date(buildDate).getFullYear() && (dayobject.day.getMonth() + 1) < (new Date(buildDate).getMonth() + 1)) {
          return true;
        }

        if (dayobject.day.getFullYear() <= new Date(buildDate).getFullYear() && (dayobject.day.getMonth() + 1) <= (new Date(buildDate).getMonth() + 1) && dayobject.day.getDate() < new Date(buildDate).getDate()) {
          return true;
        }
      }
      //date.getFullYear()>=dayobject.day.getFullYear()&&date.getMonth()+1 <= (dayobject.day.getMonth()+1)&&date.getDate() <  dayobject.day.getDate()
      //当前日期小于等于选择的日期，被禁用
      if (this.date.getFullYear() < dayobject.day.getFullYear()) {
        return true;
      }

      if (this.date.getFullYear() <= dayobject.day.getFullYear() && (this.date.getMonth() + 1) < (dayobject.day.getMonth() + 1)) {
        return true;
      }

      if (this.date.getFullYear() <= dayobject.day.getFullYear() && (this.date.getMonth() + 1) <= (dayobject.day.getMonth() + 1) && this.date.getDate() < dayobject.day.getDate()) {
        return true;
      }

      return false;
    },
    shadowVal (monobject) {
      if (this.date.getFullYear() == new Date(this.paraData.searchDate).getFullYear() && (this.date.getMonth() + 1) == parseInt(monobject.monkey)) {
        return "always";
      } else {
        return "hover";
      }
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function (year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d
    },
    //日历点击具体日期
    backErrClick (dayobject, errType) {
      if (dayobject.data.failureNum > 0) {//优先故障查询
        errType = 1
      } else if (dayobject.data.abnormalNum > 0) {//其次通讯异常
        errType = 2
      } else if (dayobject.data.offlineNum > 0) {//其次设备离线
        errType = 3
      }
      this.$emit("backYearCalendarClick", dayobject.day, errType, "year");
    }
  }
}
</script>